<template>
  <div id="container">

    <section id="login">
      <div class="wrap">
        <h2 class="cts_ttl"><span>代理ログイン</span></h2>
        <p class="alnC">
          塾生または、スタッフに成り代わってログインすることが可能です。<br>
          以下のフォームに<span class="bold">「複眼経済塾に登録済みのメールアドレス」</span>をご入力の上「代理ログイン」ボタンを押してください。<br>
        </p>
        <div class="cts_box">
          <form @submit="submitClicked">
            <div class="forget_pswd">
              <div class="input_set">
                <div class="label">登録済みメールアドレス</div>
                <input type="email" v-model="email" placeholder="例：yamada@hukugankeizai.com">
                <validation-errors :errors="errors" v-if="errors"/>
              </div>
            </div>
            <div class="button_wrap">
              <input type="submit" class="submit light" value="代理ログイン">
            </div>
          </form>
        </div>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from "@/components/ValidationErrors";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors
  },
  data() {
    return {
      email: null,
      errors: [],
    }
  },
  created() {
    //
  },
  methods: {
    submitClicked(event) {
      event.preventDefault()
      this.errors = []
      this.axios
          .post(`${this.env.api_base_url}users/staff_sign_in.json`, {
            email: this.email,
          })
          .then((response) => {
            if (response.data.result) {
              this.setFlash('ログインしました。')
              this.saveSession(response.data.user);
              if (this.$route.query.redirect) {
                this.$router.push({path: this.$route.query.redirect})
              } else {
                this.$router.push({name: 'UsersCustomerTop'})
              }
            }
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.errors = error.response.data.errors
            } else if (error.response.data.error) {
              this.errors = [error.response.data.error]
            }
          });
    }
  },
  mounted() {
    this.startAjax()
    this.axios
        .get(`${this.env.api_base_url}users/account.json`)
        .then(response => {
          this.saveSession(response.data.user);
          if (!this.isStaff()) {
            // スタッフじゃなければマイページへ
            this.$router.push({name: 'UsersCustomerAccount'})
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.finishAjax()
        })
  }
}
</script>
